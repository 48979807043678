/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { useIsReadOnly } from 'store/hooks'
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'

/* Type imports ------------------------------------------------------------- */
import type { JalonFacture } from 'API/__generated__/Api'
import {
  EtatFacture,
  WorkflowEtat,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowInvoiceCardProps {
  jalonFacture: JalonFacture;
}

const CaseWorkflowInvoiceCard: React.FC<CaseWorkflowInvoiceCardProps> = ({ jalonFacture }) => {
  const isReadOnly = useIsReadOnly()
  const navigate = useNavigate()

  const invoiceComment = (refuseMotivation?: string, total?: number) => {
    const montant = total ? `\nMontant TTC : ${total.toFixed(2)}€` : ''
    return `${montant}\n${refuseMotivation || ''}`
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Facture
        </CaseWorkflowStyledComponents.Title>
        {
          !jalonFacture.factureSimplifiee &&
          (
            jalonFacture.facture?.etat?.code === EtatFacture.Valide ||
            jalonFacture.facture?.etat?.code === EtatFacture.Regle ||
            jalonFacture.etat === WorkflowEtat.Annule
          ) ?
            <CaseWorkflowDownloadButton
              name={getCleanFilename(jalonFacture.piecejointe?.libelle || '', jalonFacture.piecejointe?.fileName || '')}
              url={jalonFacture.piecejointe?.url || ''}
            /> :
            !jalonFacture.factureSimplifiee &&
            (
              jalonFacture.facture?.etat?.code === EtatFacture.Envoye ||
              jalonFacture.facture?.etat?.code === EtatFacture.Renvoye
            ) ?
              <CaseWorkflowStyledComponents.Button
                variant="contained"
                onClick={() => navigate('facture')}
                disabled={isReadOnly}
              >
                Voir et accepter la facture
              </CaseWorkflowStyledComponents.Button> :
              <div />
        }
        <CaseWorkflowJalonText
          jalon={jalonFacture.type.code}
          date={jalonFacture.dateAffichee || ''}
          state={jalonFacture.etat}
          invoiceState={jalonFacture.facture?.etat?.code}
          comment={invoiceComment(jalonFacture.facture?.motifRefus || '', jalonFacture.facture?.pied?.montantTTC || undefined)}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowInvoiceCard
